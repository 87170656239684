<template>
  <loader />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LoginOkta',
  computed: {
    ...mapGetters('app', ['isUSSite']),
    ...mapGetters('user', ['isGuest'])
  },
  methods: {
    ...mapActions('app', ['doLoginOkta'])
  },
  async created () {
    if (!this.isUSSite && this.isGuest) {
      this.doLoginOkta({ customPath: '/' })
    } else {
      this.$router.push('/')
    }
  }
}
</script>
